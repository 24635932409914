<template>
    <add-points/>
</template>

<script>
import AddPoints from '@/components/AddPoints.vue'
export default {
    components: {
        AddPoints
    }
}
</script>

<style>

</style>