<template>
  <div>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>
    <modal-detail :weeklyData="pointsXWeekXStudent" />
    <div class="tabs is-toggle is-toggle-rounded is-right" v-if="!inFinalStretch">
      <ul>
        <li :class="{ 'is-active': showRace }">
          <a @click="toggleDisplay(true)">
            <span class="icon is-small"><i class="fas fa-running"></i></span>
            <span>Race for the Cup</span>
          </a>
        </li>
        <li :class="{ 'is-active': !showRace }">
          <a @click="toggleDisplay(false)">
            <span class="icon is-small"
              ><i class="fas fa-calendar-week"></i
            ></span>
            <span>Weekly Race</span>
          </a>
        </li>
      </ul>
    </div>

    <div>
      <graph-display
        :campusID="campusID"
        :cohort="cohort"
        :chartData="chartDataSet"
        v-if="gotData && chartDataSet && raceIsDone && !showRace && !inFinalStretch"
        style="cursor: pointer"
      />
    </div>
    <div class="mt-4">
      <race-heads
        :points="points"
        :weekNumber="weekNumber"
        @isDone="raceIsDone = true"
        v-show="showRace && gotData && !inFinalStretch"
      />
    </div>
    <final-stretch v-if="inFinalStretch"/>
    <div class="image container">
      <img
        src="@/assets/orcaloop3c.gif"
        class="has-image-centered"
        v-if="!gotData"
      />
    </div>
  </div>
</template>

<script>
import apiService from "@/services/APIService";
import GraphDisplay from "@/components/GraphDisplay.vue";
import ModalDetail from "@/components/ModalLeaderBoard.vue";
import RaceHeads from "./RaceHeads.vue";
import FinalStretch from './FinalStretch.vue';

export default {
  components: {
    GraphDisplay,
    ModalDetail,
    RaceHeads,
    FinalStretch,
  },
  props: ["campusID", "cohort"],
  data() {
    return {
      points: {},
      errorMessage: "",
      pointsRace: {},
      pointsXWeekXStudent: {},
      gotData: false,
      raceIsDone: false,
      weekNumber: -1,
      showRace: true,
    };
  },
  watch: {
    campusID: function () {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      if (this.campusID === undefined) {
        return;
      }
      apiService
        .getPointsByCampus(this.campusID, this.cohort)
        .then((response) => {
          this.points = response.data;
        })
        .catch((error) => {
          const response = error.response;
          this.errorMessage = response.data.message;
        });
      apiService
        .getPointsRace(this.campusID, this.cohort)
        .then((response) => {
          this.pointsRace = response.data;
          this.weekNumber = this.calculateWeekNumber();
          this.gotData = true;
        })
        .catch((error) => {
          const response = error.response;
          this.errorMessage = response.data.message;
        });
      apiService
        .getPointsByWeekByStudent(this.campusID, this.cohort)
        .then((response) => {
          this.pointsXWeekXStudent = response.data;
        })
        .catch((error) => {
          const response = error.response;
          this.errorMessage = response.data.message;
        });
    },
    calculateWeekNumber() {
      let week = -1;
      for (const [key, value] of Object.entries(this.pointsRace)) {
        key;
        week > value.length ? 0 : (week = value.length);
      }
      return (this.$store.getters.currentWeek-this.$store.getters.weekOffSet);
    },
    toggleDisplay(showRace) {
      this.showRace = showRace;
      if (!showRace) {
        this.weekNumber = -1;
      } else {
        this.weekNumber = this.calculateWeekNumber();
      }
    },
  },
  computed: {
    chartDataSet() {
      let weeks = [];
      for (let i = 0; i < this.$store.getters.totalWeeks; i++) {
        weeks.push("Week " + (i + 1));
      }

      let fullData = {
        labels: weeks,
      };
      let output = [];
      for (const [key, value] of Object.entries(this.pointsRace)) {
        let ds = {};
        ds.label = key;
        ds.data = [];
        if (value.length > 0) {
          ds.backgroundColor = value.find((v) => v.color).color;
        } else {
          ds.backgroundColor = "black";
        }

        // use the week to find each value
        const activeCohort = this.$store.state.campus.cohorts.find(
          (c) => c.number == this.cohort
        );
        const weekOffset = activeCohort.startWeek;
        for (let i = 0; i < this.$store.getters.totalWeeks; i++) {
          // get the datablock for the proper week
          const item = value.find((v) => v.week == weekOffset + i);
          if (item) {
            ds.data.push(item.points);
          } else {
            ds.data.push(0);
          }
        }
        // value.forEach((item) => {
        //   ds.backgroundColor = item.color;
        //   ds.data.push(item.points);
        // });
        output.push(ds);
      }
      fullData.datasets = output;

      return fullData;
    },
    inFinalStretch() {
      let elaspsedWeeks = -1;
      for (const [key, value] of Object.entries(this.pointsRace)) {
        key;
        if(value.length > elaspsedWeeks)
        {
          elaspsedWeeks = value.length;
        }
      }
      return elaspsedWeeks >= (this.$store.getters.totalWeeks-2) && !this.$store.state.token;
    },
  },
  created() {
    this.loadData();
    setTimeout(() => (this.raceIsDone = true), 5000);
  },
};
</script>

<style scoped>
.bar {
  height: 25px;
}
</style>