<template>
  <div>
    <div class="image container">
      <img src="@/assets/orcaloop3c.gif" v-if="pleaseWait" />
    </div>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <upload-students @allDone="modalDone" />
      </div>
    </div>
    <student-point-detail
      :studentID="pointsForStudent"
      :showMe="showStudentDetail"
      @hideStudentDetail="detailDone"
    />
    <section class="columns" v-show="!pleaseWait">
      <div
        class="column box mx-3"
        v-for="(item, title) in students"
        :key="title"
      >
        <div
          class="
            title
            is-5 is-flex is-justify-content-space-evenly
            has-text-white
          "
          :style="{ backgroundColor: $store.getters.classColors[title] }"
        >
          {{ title }}
        </div>
        <section
          class="columns"
          v-for="student in item"
          :key="student.studentID"
          style="border-bottom: 1px solid #ccc"
        >
          <i
            class="column is-1 fas fa-award has-text-success-dark has-text-left"
            @click="getPointsForStudent(student.studentID)"
            v-if="student.total != 0"
            style="cursor: pointer"
          ></i>
          <div class="column is-1" v-if="student.total == 0"></div>

          <div class="pt-2 column">
            {{ student.firstName }} {{ student.lastName }}
          </div>
          <div class="column is-one-quarter">{{ student.total }}</div>
          <i
            class="
              column
              is-one-quarter
              fas
              fa-dumpster-fire
              has-text-danger-dark has-text-right
            "
            @click="areYouSure(student)"
            style="cursor: pointer"
          ></i>
          <!-- @click="deleteStudent(student.studentID, title)" -->
        </section>
      </div>
    </section>
    <confirm
      :showMe="showConfirmModal"
      :displayData="confirmModalData"
      @confirmResponse="deleteStudent"
    />
  </div>
</template>

<script>
import apiService from "@/services/APIService.js";
import UploadStudents from "@/components/UploadStudents.vue";
import StudentPointDetail from "./StudentPointDetail.vue";
import Confirm from "./Confirm.vue";

export default {
  components: {
    UploadStudents,
    StudentPointDetail,
    Confirm,
  },
  props: ["modalIn"],
  data() {
    return {
      students: {},
      errorMessage: "",
      showModal: this.modalIn,
      pointsForStudent: 0,
      showStudentDetail: false,
      pleaseWait: true,
      showConfirmModal: false,
      confirmModalData: {
        title: "",
      },
      studentToDelete: {},
    };
  },
  methods: {
    modalDone() {
      //this.getStudents();
      this.showModal = false;
      this.$router.push({ name: "editstudents" });
    },
    detailDone() {
      // refresh the page
      this.showStudentDetail = false;
      this.pleaseWait = true;
      this.getStudents();
    },
    getStudents() {
      apiService
        .getStudents(
          this.$store.state.campus.campusID,
          this.$store.getters.currentCohort
        )
        .then((response) => {
          this.students = response.data;
          this.pleaseWait = false;
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
          this.errorMessage = response.data.message;
        });
    },
    getPointsForStudent(studentID) {
      this.pointsForStudent = studentID;
      this.showStudentDetail = true;
    },
    deleteStudent(confirm) {
      // reset the dialog
      this.showConfirmModal = false;
      if (confirm) {
        apiService
          .deleteStudent(this.studentToDelete.studentID)
          .then(() => {
            // all good, take out the student id
            this.students[this.studentToDelete.className] = this.students[
              this.studentToDelete.className
            ].filter((s) => s.studentID != this.studentToDelete.studentID);
            this.studentToDelete = {};
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 401) {
              this.$store.commit("LOGOUT");
              this.$router.push({ name: "home" });
            }
            this.errorMessage = response.data.message;
          });
      }
    },
    areYouSure(studentObject) {
      this.confirmModalData.title = studentObject.fullName;
      this.studentToDelete = studentObject;
      this.showConfirmModal = true;
    },
  },
  created() {
    this.getStudents();
  },
};
</script>


<style>
</style>