<template>
  <div class="box content">
    <graph :campusID="campus.campusID" :cohort="campus.currentCohort" />
  </div>
</template>

<script>
import apiService from "../services/APIService";
import Graph from "@/components/Graph.vue";

export default {
  components: {
    Graph,
  },
  data() {
    return {
      campus: {},
    };
  },
  created() {
    apiService
      .getCampus(this.$route.params.campus)
      .then((response) => {
        this.campus = response.data;
        this.$store.commit("SET_CAMPUS", this.campus);
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
      });
    localStorage.setItem("campusAbbreviation", this.$route.params.campus.trim());
  },
};
</script>

<style>
</style>