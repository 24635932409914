import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Register from '../views/Register.vue'
// import store from '../store/index'
import Admin from '@/views/Admin.vue'
import Campus from '@/views/CampusView.vue'
import AddPoints from '@/views/AddPoints.vue'
import AddStudents from '@/views/AddStudents.vue'
import PointsForStudent from '@/views/PointsForStudent.vue'
import EditStudents from '@/views/EditStudents.vue'
import ReviewPoints from '@/views/ReviewPoints.vue'
import DefineCategories from '@/views/DefineCategories.vue'
import ClassImages from '@/views/ClassImages.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(Router)

/**
 * The Vue Router is used to "direct" the browser to render a specific view component
 * inside of App.vue depending on the URL.
 *
 * It also is used to detect whether or not a route requires the user to have first authenticated.
 * If the user has not yet authenticated (and needs to) they are redirected to /login
 * If they have (or don't need to) they're allowed to go about their way.
 */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/addpoints',
      name: 'addpoints',
      component: AddPoints,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/pointsforstudent',
      name: 'pointsforstudent',
      component: PointsForStudent,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/addstudents',
      name: 'addstudents',
      component: AddStudents,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/editstudents',
      name: 'editstudents',
      component: EditStudents,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/reviewpoints',
      name: 'reviewpoints',
      component: ReviewPoints,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/definecategories',
      name: 'definecategories',
      component: DefineCategories,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/admin/classimages',
      name: 'classimages',
      component: ClassImages,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/campus/:campus",
      name: "campus",
      component: Campus,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/notfound',
      name: "notfound",
      component: NotFound,
      meta: {
        requiresAuth: false
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  // Determine if the route requires Authentication
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  // If it does and they are not logged in, send the user to "/login"
  if (requiresAuth && !localStorage.getItem('token')) {
    next("/login");
  } else {
    // Else let them go to their next destination
    next();
  }
});

export default router;
