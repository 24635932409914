<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head has-background-danger">
        <p class="modal-card-title has-text-white">Delete {{ displayData.title }}?</p>
        <button class="delete" aria-label="close" @click="hideMe(false)"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
            <div class="column is-one-fifth">
                <img src="@/assets/Thinking.png" />
            </div>
            <div class="column is-size-4">This will <span class="has-text-danger has-text-weight-bold">delete</span> the record for <span class="has-text-weight-bold">{{displayData.title}}</span>. If that's what you want to do, click the <span class="has-background-danger has-text-white">Delete</span> button. Otherwise, just click Cancel.</div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="hideMe(true)">Delete</button>
        <button class="button" @click="hideMe(false)">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showMe", "displayData"],
  data() {
      return {
          showModal: false,
      }
  },
  methods: {
    hideMe(yes) {
      this.showModal = false;
      this.$emit("confirmResponse", yes);
    },
  },
  watch: {
      showMe() {
          this.showModal = this.showMe;
      }
  }
};
</script>

<style>
</style>