<template>
  <div id="modal_main" class="modal" :class="{ 'is-active': showSD }">
    <div class="modal-background"></div>
    <div class="modal-card" style="width: 80%">
      <header class="modal-card-head">
        <p class="modal-card-title" v-if="pointsForStudent.length > 0">
          {{ pointsForStudent[0].student_First }}
          {{ pointsForStudent[0].student_Last }}
        </p>
        <button class="delete" aria-label="close" @click="hideMe"></button>
      </header>
      <section class="modal-card-body">
        <table class="table" style="width: 100%">
          <thead>
            <tr class="title is-5">
              <td>Reason</td>
              <td>Amount</td>
              <td>Awarded by</td>
              <td>Awarded on</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in pointsForStudent" :key="item.housePointID">
              <td>{{ item.description }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.staff_First }} {{ item.staff_Last }}</td>
              <td>{{ new Date(item.awardedDate).toDateString() }}</td>
              <td>
                <i
                  class="
                    column
                    is-one-quarter
                    fas
                    fa-dumpster-fire
                    has-text-danger-dark has-text-right
                  "
                  @click="deletePoints(item.housePointID)"
                  style="cursor: pointer"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="hideMe">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/APIService.js";

export default {
  props: ["showMe", "studentID"],
  data() {
    return {
      showSD: this.showMe,
      pointsForStudent: [],
    };
  },
  methods: {
    hideMe() {
      this.showEE = false;
      this.$emit("hideStudentDetail", false);
    },
    deletePoints(hpID) {
      this.pointsForStudent = this.pointsForStudent.filter(
        (ps) => ps.housePointID != hpID
      );
      apiService
        .deleteHousePoints(hpID)
        .then(() => {
          // if no more points, close the window
          if (this.pointsForStudent.length == 0) {
            this.hideMe();
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
        });
    },
  },
  watch: {
    showMe() {
      this.showSD = this.showMe;
    },
    studentID() {
      apiService
        .getPointsForStudent(
          this.$store.state.campus.campusID,
          this.$store.getters.currentCohort,
          this.studentID
        )
        .then((response) => {
          this.pointsForStudent = response.data;
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
          this.errorMessage = response.data.message;
        });
    },
  },
};
</script>

<style>
</style>