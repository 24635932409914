<template>
  <div>
    <aside class="menu">
      <section id="forAdmin" v-if="isAdmin">
      <p class="menu-label">Points</p>
      <ul class="menu-list">
        <li>
          <router-link v-bind:to="{ name: 'addpoints' }"
            ><i class="fas fa-plus-circle"></i> Add Points</router-link
          >
        </li>
        <!-- <li>
          <router-link v-bind:to="{ name: 'reviewpoints' }">
            <i class="fas fa-clipboard-check"></i> Review Points</router-link
          >
        </li> -->
        <li>
          <router-link v-bind:to="{ name: 'definecategories' }"
            ><i class="fas fa-pen"></i> Define Points</router-link
          >
        </li>
      </ul>
      <p class="menu-label">Students</p>
      <ul class="menu-list">
        <li>
          <router-link v-bind:to="{ name: 'editstudents' }"
            ><i class="fas fa-user-friends"></i> Student List</router-link
          >
        </li>
        <li>
          <router-link v-bind:to="{ name: 'addstudents' }"
            ><i class="fas fa-user-plus"></i> Add Students</router-link
          >
        </li>
      </ul>
      <p class="menu-label">Account</p>
      <ul class="menu-list">
        <li>
          <a><i class="fas fa-user"></i> Profile</a>
        </li>
        <li>
          <router-link v-bind:to="{ name: 'logout' }"
            ><i class="fas fa-fire-alt"></i> Logout</router-link
          >
        </li>
      </ul>
      </section>
      <p class="menu-label">Campus</p>
      <section
        class="
          box
          is-flex is-justify-content-space-between is-flex-direction-column
        "
      >
        <label
          class="checkbox"
          v-for="campus in campuses"
          :key="campus.campusID"
          v-show="!isChanging"
        >
          <input
            type="radio"
            :value="campus.campusID"
            v-model="selectedCampus"
            class="radio"
            :id="campus.name"
            @change="changeCampus"
          />
          {{ campus.name }}</label
        >
        <img src="@/assets/loading.gif" v-show="isChanging" />
      </section>
    </aside>
  </div>
</template>

<script>
import authService from "@/services/AuthService.js";

export default {
  data() {
    return {
      campuses: [],
      selectedCampus: -1,
      isChanging: false,
    };
  },
  methods: {
    changeCampus() {
      authService
        .changeCampus(this.selectedCampus)
        .then((response) => {
          if (response.data) {
            this.$store.commit("SET_USER", response.data);
            this.$store.commit("SET_CAMPUS", response.data.campuses.find(c => c.isCurrent));
            this.$router.push("/admin");
          }
          this.isChanging = false;
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
        });
    },
  },
  created() {
    this.selectedCampus = this.$store.state.campus.campusID;
    if(this.$store.state.user.campuses)
    {
      this.campuses = this.$store.state.user.campuses;
    }
    // authService.getCampuses().then((response) => {
    //   this.campuses = response.data;
    // });
  },
  computed: {
        isAdmin() {
      if (this.$store.state.user.roles) {
        return this.$store.state.user.roles.includes("Admin");
      } else {
        return false;
      }
    }
  }
};
</script>

<style>
</style>