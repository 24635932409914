<template>
  <div class="card">
    <header class="card-header">
      <span class="card-header-title">Upload Students from File</span>
    </header>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>

    <div class="card-content">
      <div class="content">
        <section class="is-flex">
          <div class="pb-4">
            Choose a csv file with columns for at least First Name, Last Name,
            Email, and Bit Bucket User name.
          </div>
          <div class="file">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                ref="file"
                @change="loadTextFromFile"
              />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label"> Choose a file… </span>
              </span>
            </label>
          </div>
        </section>
        <div class="is-flex is-flex-direction-column">
          <label class="label" v-show="dropDownData.length > 0"
            >First Name
            <select
              name="firstName"
              id="firstName"
              class="select"
              v-model="itemData.firstNameColumn"
            >
              <option
                v-for="item in dropDownData"
                :value="item.index"
                :key="item.index"
              >
                {{ item.text }}
              </option>
            </select>
          </label>
          <label class="label" v-show="dropDownData.length > 0"
            >Last Name
            <select
              name="lasstName"
              id="lasstName"
              class="select"
              v-model="itemData.lastNameColumn"
            >
              <option
                v-for="item in dropDownData"
                :value="item.index"
                :key="item.index"
              >
                {{ item.text }}
              </option>
            </select>
          </label>
          <label class="label" v-show="dropDownData.length > 0"
            >Email
            <select
              name="Email"
              id="Email"
              class="select"
              v-model="itemData.emailColumn"
            >
              <option
                v-for="item in dropDownData"
                :value="item.index"
                :key="item.index"
              >
                {{ item.text }}
              </option>
            </select>
          </label>
          <label class="label" v-show="dropDownData.length > 0"
            >Class
            <select
              name="class"
              id="class"
              class="select"
              v-model="itemData.classSectionId"
            >
              <option v-for="(value, key) in classes" :value="key" :key="value">
                {{ value }}
              </option>
            </select>
          </label>
          <section class="is-flex is-flex-direction-column">
            <label class="checkbox" v-show="dropDownData.length > 0">
              <input
                type="checkbox"
                class="checkbox"
                v-model="itemData.hasHeaderRow"
              />
              File has Header Row
            </label>
          </section>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        color="blue darken-1"
        flat
        @click="close"
        v-show="!processingUpload"
        class="
          button
          card-footer-item
          has-background-danger-dark has-text-white
        "
      >
        Cancel
      </button>
      <button
        color="blue darken-1"
        class="
          button
          card-footer-item
          has-background-success-dark has-text-white
        "
        @click="saveColumns()"
        :disabled="processingUpload ? true : itemData.classSectionId == 0"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/APIService.js";

export default {
  data() {
    return {
      dropDownData: [],
      itemData: {
        firstNameColumn: 0,
        lastNameColumn: 0,
        emailColumn: 0,
        classSectionId: 0,
        file: "",
        hasHeaderRow: false,
      },
      processingUpload: false,
      errorMessage: "",
      classes: {},
      missingStudents: {},
    };
  },
  methods: {
    loadTextFromFile(ev) {
      //reset the dropDownData
      this.dropDownData = [];
      const file = ev.target.files[0];
      const reader = new FileReader();
      this.itemData.file = file;
      reader.onload = (e) => {
        this.$emit("load", e.target.result);
        const firstLine = e.target.result.split("\n").shift();
        const allElements = firstLine.split(",");
        console.log(firstLine);
        console.table(allElements);
        // map to objects
        for (let i = 0; i < allElements.length; i++) {
          this.dropDownData.push({ index: i, text: allElements[i] });
        }
        //Object.assign(this.dropDownData, allElements);
      };
      // ad the none object
      reader.readAsText(file);
    },
    saveColumns() {
      //this.scrollToTop();
      //this.pleaseWait = !this.pleaseWait;
      // make the form data
      var form_data = new FormData();
      this.processingUpload = true;
      for (var key in this.itemData) {
        form_data.append(key, this.itemData[key]);
      }
      apiService
        .postAddStudents(form_data)
        .then((response) => {
          console.log("We are back!");
          this.missingStudents = response.data;
          this.close();
        })
        .catch((e) => {
          const response = e.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }

          if (e.response) {
            console.log(e.response.data);
          }
          this.close();
        })
        .then(() => {
          this.processingUpload = false;
        });
    },
    resetData() {
      this.dropDownData = [];
      this.hasHeaderRow = false;
    },
    close() {
      this.resetData();
      this.$emit("allDone");
    },
  },
  created() {
    apiService
      .getClasses(
        this.$store.state.campus.campusID,
        this.$store.state.campus.currentCohort
      )
      .then((response) => {
        this.classes = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }

        this.errorMessage = response.data.message;
      });
  },
};
</script>