<template>
  <div>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>
    <graph
      :campusID="$store.state.campus.campusID"
      :cohort="$store.getters.currentCohort"
    />
  </div>
</template>

<script>
import Graph from "@/components/Graph.vue";

export default {
  components: {
    Graph,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
};
</script>

<style>
</style>