<template>
  <div
    class="modal"
    :class="{ 'is-active': $store.state.graphModalData.showModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $store.state.graphModalData.title }} Contributors
        </p>
        <button class="delete" aria-label="close" @click="toggleModal"></button>
      </header>
      <section class="modal-card-body">
        <div v-for="(item, title) in dataForWeek" :key="title">
          <section class="is-flex is-flex-direction-column">
            <table class="table">
              <thead>
                <tr
                  :style="{
                    backgroundColor: $store.getters.classColors[title],
                  }"
                >
                  <td colspan="2" class="has-text-white">{{ title }}</td>
                </tr>
              </thead>
              <tr v-for="student in item" :key="student.studentID">
                <td class="has-text-left">{{ student.fullName }}</td>
                <td class="has-text-right">{{ student.total }}</td>
              </tr>
              <tfoot>
                <tr>
                  <td colspan="2"></td>
                </tr>
              </tfoot>
            </table>
          </section>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="toggleModal">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/APIService.js";

export default {
  props: ["weeklyData"],
  data() {
    return {
      pointsXWeekXStudent: {},
    };
  },
  methods: {
    toggleModal() {
      this.$store.commit("SET_GRAPHMODALTOGGLE");
    },
  },
  computed: {
    dataForWeek() {
      let filteredData = {};
      for (let [key, value] of Object.entries(this.pointsXWeekXStudent)) {
        // value = value.filter(f => f.week == this.$store.graphModalData.weekNumber);
        filteredData[key] = value.filter(
          (f) => f.week == this.$store.state.graphModalData.weekNumber - 1
        );
      }
      return filteredData;
    },
    classColors() {
      let colors = {};
      let cohortObject = this.$store.state.campus.cohorts.find(
        (c) => c.number == this.$store.getters.currentCohort
      );
      cohortObject.classes.forEach((classData) => {
        colors[classData.className] = classData.color;
      });
      return colors;
    },
  },
  created() {
    apiService
      .getPointsByWeekByStudent(
        this.$store.state.campus.campusID,
        this.$store.getters.currentCohort
      )
      .then((response) => {
        this.pointsXWeekXStudent = response.data;
      })
      .catch((error) => {
        const response = error.response;
        this.errorMessage = response.data.message;
      });
  },
};
</script>

<style>
</style>