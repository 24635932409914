<template>
  <div id="app" v-if="!killFrame">
    <easter-egg v-if="ee" :showMe="ee" @shhh="ee = false" />
    <section class="hero mx-6">
      <div class="hero-body has-text-centered">
        <img
          src="@/assets/techelevator.png"
          class="is-pulled-left"
          style="width: 50px"
          @click="goHome"
        />
        <span class="title"
          >House of Metal Cup {{ $store.state.campus.name }}
          <i
            class="fas"
            style="color: gold"
            :class="{
              'fa-trophy': eggCounter < 4,
              'fa-egg': eggCounter > 3,
              eggEffect: eggCounter > 3,
            }"
            @dblclick="easterEgg"
          ></i
          ><i
            class="fas fa-user-cog is-pulled-right"
            style="color: green"
            @click="goAdmin"
            v-if="!$store.state.token"
          ></i>
          <router-link
            v-bind:to="{ name: 'logout' }"
            class="is-pulled-right"
            v-if="$store.state.token"
            title="Logout"
            ><i class="fas fa-fire-alt has-text-danger"></i></router-link
        ></span>
      </div>
    </section>

    <div
      class="tabs is-centered is-medium is-boxed is-fullwidth"
      v-if="$store.state.token"
    >
      <ul>
        <router-link
          v-bind:to="{ name: 'admin' }"
          exact-active-class="is-active"
          tag="li"
          ><a
            ><i class="fas fa-trophy mr-2" style="color: gold"></i> Race for the
            Cup</a
          ></router-link
        >
        <router-link
          v-bind:to="{ name: 'addpoints' }"
          exact-active-class="is-active"
          tag="li"
          ><a
            ><span class="is-icon is-small mr-2"
              ><i class="fas fa-plus-circle" style="color: green"></i></span
            ><span>Award Points</span></a
          ></router-link
        >
        <router-link
          v-bind:to="{ name: 'pointsforstudent' }"
          exact-active-class="is-active"
          tag="li"
          ><a
            ><i class="fas fa-search-plus mr-2" style="color: green"></i> Search
            Students</a
          ></router-link
        >
        <router-link
          v-bind:to="{ name: 'editstudents' }"
          exact-active-class="is-active"
          tag="li"
          ><a><i class="fas fa-user-friends mr-2"></i> Students</a></router-link
        >
        <router-link
          v-bind:to="{ name: 'classimages' }"
          exact-active-class="is-active"
          tag="li"
          v-if="$store.state.user.roles.includes('StatusAdmin')"
          ><a><i class="fas fa-images mr-2"></i> Class Images</a></router-link
        >
      </ul>
    </div>
    <section class="box mx-6">
      <div class="columns">
        <div class="column is-one-fifth" v-if="isAdmin || isMultiCampus">
          <navigation />
        </div>
        <div class="column" style="overflow:auto;">
          <router-view />
        </div>
      </div>
    </section>
    <span class="has-text-weight-bold has-text-white"
      >v. {{ this.$version }}</span
    >
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import EasterEgg from "@/components/EasterEgg.vue";
import apiService from "@/services/APIService.js";

export default {
  data() {
    return {
      ee: false,
      eggCounter: 0,
    };
  },
  components: {
    Navigation,
    EasterEgg,
  },
  methods: {
    goHome() {
      if (this.$store.state.token != "") {
        this.$router.push("/admin");
      } else {
        localStorage.clear();
        this.$router.push({ name: "home" });
      }
    },
    goAdmin() {
      localStorage.clear();
      this.$router.push({ name: "admin" });
    },
    easterEgg() {
      this.eggCounter++;
      if (this.eggCounter > 4) {
        this.ee = true;
        this.eggCounter = 0;
      }
    },
  },
  created() {
    if (this.$store.state.token != "") {
      // refresh the data
      apiService
        .refreshData()
        .then((response) => {
          if (response.status == 200) {
            this.$store.commit("SET_AUTH_TOKEN", response.data.token);
            this.$store.commit("SET_USER", response.data.user);
            this.$store.commit("SET_CAMPUS", response.data.user.campuses.find(c => c.isCurrent));
            this.$router.push("/admin");
          }
        })
        .catch(() => {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        });
    }
    if (localStorage.getItem("campusAbbreviation")) {
      this.$router.push({
        name: "campus",
        params: { campus: localStorage.getItem("campusAbbreviation") },
      });
    }
  },
  computed: {
    killFrame(){
      return self != top
    },
    isAdmin() {
      if (this.$store.state.user.roles) {
        return this.$store.state.user.roles.includes("Admin");
      } else {
        return false;
      }
    },
    isMultiCampus() {
      if(this.$store.state.user.campuses) {
        return this.$store.state.user.campuses.length > 1
      } else {
        return false;
      }
    }
  },
};
</script>
<style scoped>
@keyframes wheelHueColor {
  from,
  to {
    color: rgb(236, 100, 75);
  }
  10% {
    color: rgb(219, 10, 91);
  }
  20% {
    color: rgb(174, 168, 211);
  }
  30% {
    color: rgb(89, 171, 227);
  }
  40% {
    color: rgb(37, 116, 169);
  }
  50% {
    color: rgb(3, 201, 169);
  }
  60% {
    color: rgb(22, 160, 133);
  }
  70% {
    color: rgb(233, 212, 96);
  }
  80% {
    color: rgb(243, 156, 18);
  }
  90% {
    color: rgb(236, 240, 241);
  }
}
.eggEffect {
  color: gold;
  animation: wheelHueColor 3s infinite;
  /* transform: translateY(-50%); */
}
</style>
