<template>
      <article class="message">
        <div class="message-header">
          <p>Ooops I can't seem to find that page.</p>
        </div>
        <div class="message-body is-flex is-justify-content-center">
          <img src="../assets/Shrug.png"/>
        </div>
      </article>

</template>

<script>
export default {

}
</script>

<style>

</style>