
<script>
// import apiService from "@/services/APIService";
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["campusID", "cohort", "chartData"],
  data() {
    return {
      points: {},
      errorMessage: "",
      pointsRace: {},
      pointsXWeekXStudent: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (evt, item) => {
          this.showWeeklyDetail(evt, item);
        },
        // 'onClick' : (evt, item) => { alert(item[0]['_model'].label) },
      },
    };
  },
  watch: {
    campusID: function () {
      this.loadData();
    },
    chartData: function () {
      this.$data._chart.data.datasets = this.chartData;
      this.$data._chart.update();
    },
  },
  methods: {
    showWeeklyDetail(evt, item) {
      if (this.$store.state.token) {
        this.$store.commit("SET_GRAPHTITLE",item[0]["_model"].label);
        let week = parseInt(item[0]["_model"].label.substring(5, 8))
        week += this.$store.getters.weekOffSet;
        this.$store.commit("SET_GRAPHWEEK",week);
        this.$store.commit("SET_GRAPHMODALTOGGLE");
        //this.$store.state.graphModalData.showModal = true;
      }
    },
    loadData() {
    },
  },
  computed: {},
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>

<style scoped>
</style>