import axios from 'axios';

// const axiosClient = axios.create({
//   // baseURL: "https://localhost:44315/api"
//   baseURL: 'https://te-housecup-api.azurewebsites.net/api'
  
// });

export default {

  login(user) {
    return axios.post('/login', user)
  },

  register(user) {
    return axios.post('/register', user)
  },

  getCampuses(){
    return axios.get('/campuses');
  },
  changeCampus(campusID) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return axios.post(`/campus/${campusID}`)
  },


}
