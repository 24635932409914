<template>
  <div>
    <section class="columns">
      <div class="column">
        <label class="title is-5"> Search for Students</label>
      </div>
      <div class="column">
        <label class="title is-5"> Choose Category</label>
      </div>
      <div class="column is-one-fifth">
        <label class="title is-5"> Date of Award</label>
      </div>
      <div class="column is-2">
        <button
          class="button is-success is-pulled-right content"
          type="button"
          :class="{ 'is-loading': saving }"
          :disabled="formFilled"
          @click="savePoints()"
        >
          Save Points
        </button>
      </div>
    </section>
    <section class="columns">
      <div class="column">
        <input
          type="text"
          v-model="SearchString"
          placeholder="Search for Students"
          class="input is-rounded"
        />
        <div v-for="(classSection, index) in filteredStudents" :key="index">
          <div v-for="student in classSection" :key="student.studentID">
            <label class="checkbox">
              <input
                type="checkbox"
                :value="student.studentID"
                v-model="addPoints.pointsFor"
                class="checkbox"
                :id="student.studentID"
              />
              {{ student.firstName }} {{ student.lastName }}</label
            >
          </div>
        </div>
      </div>
      <div class="column">
        <div v-for="category in pointCategories" :key="category.categoryID">
          <label class="checkbox">
            <input
              type="checkbox"
              :value="category.categoryID"
              v-model="addPoints.selectedCategories"
              class="checkbox"
              :id="category.categoryID"
            />
            {{ category.description }} ({{ category.pointValue }})</label
          >
        </div>
      </div>
      <!-- <date-picker
        class="field column is-one-fifth"
        v-model="addPoints.awardedDate"
      ></date-picker> -->
      <input
        class="input is-rounded column is-one-fifth"
        v-model="addPoints.awardedDate"
        type="date"
        id="AwardDate"
      />

      <div class="column is-2"></div>
    </section>
  </div>
</template>

<script>
import apiService from "../services/APIService";
// import DatePicker from "vuejs-datepicker";

export default {
  components: {
    // DatePicker,
  },
  data() {
    return {
      SearchString: "",
      students: {},
      pointCategories: {},
      errorMessage: "",
      addPoints: {
        pointsFor: [],
        selectedCategories: [],
        awardedDate: "",
      },
      saving: false,
    };
  },
  computed: {
    filteredStudents() {
      let filtered = {};
      for (const [key, value] of Object.entries(this.students)) {
        if (this.SearchString != "") {
          filtered[key] = value.filter(
            (x) =>
              x.fullName
                .toLowerCase()
                .includes(this.SearchString.toLowerCase()) ||
              this.addPoints.pointsFor.indexOf(x.studentID) > -1
          );
        } else {
          // just show the checked ones
          filtered[key] = value.filter(
            (x) => this.addPoints.pointsFor.indexOf(x.studentID) > -1
          );
        }
      }
      return filtered;
    },
    selectedStudents() {
      let selected = [];
      for (const [key, value] of Object.entries(this.students)) {
        key;
        selected.push(value);
      }
      return selected.filter(
        (x) => this.addPoints.pointsFor.indexOf(x.studentID) > -1
      );
    },
    formFilled() {
      return !(
        this.addPoints.selectedCategories.length &&
        this.addPoints.pointsFor.length
      );
    },
  },
  created() {
    apiService
      .getStudents(
        this.$store.state.campus.campusID,
        this.$store.getters.currentCohort
      )
      .then((response) => {
        this.students = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
        this.errorMessage = response.data.message;
      });
    apiService
      .getPointCategories(this.$store.state.campus.campusID)
      .then((response) => {
        this.pointCategories = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
        this.errorMessage = response.data.message;
      });
    this.addPoints.awardedDate = new Date().toISOString().substring(0, 10);
  },
  methods: {
    savePoints() {
      apiService
        .postAddPoints(this.addPoints)
        .then((response) => {
          this.students = response.data;
          this.$router.push("/admin");
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
          this.errorMessage = response.data.message;
        });
    },
  },
};
</script>

<style>
</style>