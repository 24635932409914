<template>
  <div>
    <section class="columns">
      <div id="raceWay" ref="raceWay" class="column">
        <div
          class="box"
          v-for="(classData, index) in points"
          :key="classData.className + 'raceway'"
        >
          <div
            :id="classData.className"
            style="height: 127px"
            :style="{
              width: imageAnimate[index].currentPosition + 'px',
              backgroundColor: classData.color,
            }"
            v-show="points.length > 0"
            :title="points.find(obj => obj.className == classData.className).total + ' points'"
          >
          <div class="is-flex is-flex-direction-column">
            <img
              :src="$store.getters.raceHeads[classData.className]"
              style="width: 127px; height: 127px"
              :style="{ left: imageAnimate[index].currentPosition + 'px' }"
            />
            <div v-if="false">{{points.find(obj => obj.className == classData.className).total}} points</div>
            </div>
          </div>
        </div>
        <div
          style="height: 150px"
          :style="{
            width: '100%',
          }"
          class="box"
          v-show="points.length == 0"
          v-for="(item, index) in $store.getters.raceHeads"
          :key="index"
        >
          <img
            :src="$store.getters.raceHeads[index]"
            style="width: 127px; height: 127px"
          />
        </div>
      </div>
      <div class="column is-1 finishLine"></div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["points", "weekNumber"],
  data() {
    return {
      windowWidth: 0,
      imageAnimate: [],
      timer: "",
    };
  },
  methods: {
    setPosition() {
      // calculate each week distance
      let weekLength =
        (document.getElementById("raceWay").clientWidth - 229) / this.$store.getters.totalWeeks;
      let totalLength = weekLength * (this.weekNumber > 4 ? this.weekNumber : 4);
      let mostPoints = Math.max.apply(
        Math,
        this.points.map(function (p) {
          return p.total;
        })
      );
      this.imageAnimate = [];
      this.points.forEach((p, index) => {
        this.imageAnimate.push({ currentPosition: 0, maxPosition: 1 });
        this.imageAnimate[index].maxPosition =
          (p.total / mostPoints) * totalLength;
      });
    },
    moveImage() {
      if (!this.animateDone()) {
        let localTimer = setInterval(() => {
          this.imageAnimate.forEach((p) => {
            // if we are at max, don't do anything
            if (p.currentPosition < p.maxPosition) {
              p.currentPosition += 10;
            }
          });
          //this.moveImage();
        }, 30);
        this.$store.commit("SET_TIMER", localTimer);
      }
    },
    animateDone() {
      if (this.imageAnimate.length > 0) {
        return this.imageAnimate.reduce((reducer, part) => {
          return reducer && part.currentPosition >= part.maxPosition;
        }, false);
      }
      return false;
    },
  },
  computed: {},
  watch: {
    windowWidth() {
      this.setPosition();
      this.moveImage();
    },
    weekNumber() {
      if (this.weekNumber == -1) {
        this.$store.commit("CLEAR_TIMER");
      } else {
        this.setPosition();
        this.moveImage();
      }
    },
    immediate: true,
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>

<style>
img {
  position: relative;
}
.finishLine {
  background-image: linear-gradient(45deg, black 25%, transparent 25%),
    linear-gradient(-45deg, black 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, black 75%),
    linear-gradient(-45deg, transparent 75%, black 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
</style>