import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

/*
 * The authorization header is set for axios when you login but what happens when you come back or
 * the page is refreshed. When that happens you need to check for the token in local storage and if it
 * exists you should set the header so that it will be attached to each request
 */
const currentToken = localStorage.getItem('token')
const currentUser = JSON.parse(localStorage.getItem('user'));
const currentCampus = JSON.parse(localStorage.getItem('campus'));

if (currentToken != null) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentToken}`;
}

export default new Vuex.Store({
  state: {
    token: currentToken || '',
    user: currentUser || {},
    campus: currentCampus || { name: '' },
    graphModalData: {
      showModal: false,
      title: 'Week 1',
      weekNumber: 0
    },
    timer: ''
  },
  mutations: {
    SET_TIMER(state, timer) {
      state.timer = timer;
    },
    CLEAR_TIMER(state) {
      clearInterval(state.timer);
    },
    SET_AUTH_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    SET_CAMPUS(state, campus) {
      state.campus = campus;
      localStorage.setItem('campus', JSON.stringify(campus));
    },    
    SET_GRAPHTITLE(state, title) {
      state.graphModalData.title = title;
    },
    SET_GRAPHWEEK(state, week) {
      state.graphModalData.weekNumber = week;
    },
    SET_GRAPHMODALTOGGLE(state) {
      state.graphModalData.showModal = !state.graphModalData.showModal;
    },
    SET_CLASS_COLORS(state, colors) {
      state.classColors = colors;
    },
    SET_CLASS_RACE_IMAGE(state, { classID, url }) {
      let cohortObject = state.campus.cohorts.find(c => c.number == state.campus.currentCohort);
      let classObject = cohortObject.classes.find(cl => cl.classId == classID);
      classObject.raceImage = url;
    },
    LOGOUT(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('campus');
      state.token = '';
      state.user = {};
      state.campus = { name: '' };
      state.campuses = [];
      state.graphModalData = {
        showModal: false,
        title: 'Week 1',
        weekNumber: 0
      };
      axios.defaults.headers.common = {};
    }
  },
  getters: {
    currentCohort: state => {
      return state.campus.currentCohort;
    },
    weekOffSet: state => {
      let cohortObject = state.campus.cohorts.find(c => c.number == state.campus.currentCohort);
      return cohortObject.startWeek;
    },
    currentWeek: state => {
      let cohortObject = state.campus.cohorts.find(c => c.number == state.campus.currentCohort);
      return cohortObject.currentWeek;
    },
    totalWeeks: state => {
      let cohortObject = state.campus.cohorts.find(c => c.number == state.campus.currentCohort);
      let start = new Date(cohortObject.startDate);
      let end = new Date(cohortObject.endDate);
      return Math.round((end-start)/(7*24*60*60*1000));
    },
    userToken: state => {
      return state.token;
    },
    classColors: state => {
      let colors = {};
      let cohortObject = state.campus.cohorts.find(
        (c) => c.number == state.campus.currentCohort
      );
      cohortObject.classes.forEach((classData) => {
        colors[classData.className] = classData.color;
      });
      return colors;
    },
    raceHeads: state => {
      let heads = {};
      let cohortObject = state.campus.cohorts.find(
        (c) => c.number == state.campus.currentCohort
      );
      cohortObject.classes.forEach((classData) => {
        heads[classData.className] = classData.raceImage;
      });
      return heads;
    },
    classData: state => {
      let cohortObject = state.campus.cohorts.find(
        (c) => c.number == state.campus.currentCohort
      );
      return cohortObject.classes;
    }

  }
})
