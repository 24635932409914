<template>
  <div id="login" class="container">
    <form class="box" @submit.prevent="login">
      <h1 class="h3 mb-3 font-weight-normal">Please Sign In</h1>
      <div
        class="alert alert-danger"
        role="alert"
        v-if="invalidCredentials"
      >Invalid username and password!</div>
      <div
        class="alert has-background-warning"
        role="alert"
        v-if="this.$route.query.registration"
      >Thank you for registering, check your email for a confirmation. After you click the confirmation link, you will be able to log in.</div>
      <label for="username" class="label">Username</label>
      <input
        type="text"
        id="username"
        class="input"
        placeholder="Username"
        v-model="user.username"
        required
        autofocus
      />
      <label for="password" class="label">Password</label>
      <input
        type="password"
        id="password"
        class="input"
        placeholder="Password"
        v-model="user.password"
        required
      />
      <router-link :to="{ name: 'register' }">Need an account?</router-link>
      <button type="submit" class="button is-success is-pulled-right content"
      :class="{'is-loading':loggingIn}"
      v-on:click="loggingIn = true">Sign in</button>
    </form>
  </div>
</template>

<script>
import authService from "../services/AuthService";

export default {
  name: "login",
  components: {},
  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      invalidCredentials: false,
      loggingIn: false
    };
  },
  methods: {
    login() {
      authService
        .login(this.user)
        .then(response => {
          if (response.status == 200) {
            this.$store.commit("SET_AUTH_TOKEN", response.data.token);
            this.$store.commit("SET_USER", response.data.user);
            // get active campus
            let activeCampus = response.data.user.campuses.find(c => c.isCurrent);
            this.$store.commit("SET_CAMPUS", activeCampus);
            this.$router.push("/Admin");
            this.loggingIn = false;
          }
        })
        .catch(error => {
          const response = error.response;

          if (response.status === 401) {
            this.invalidCredentials = true;
          }
          this.loggingIn = false;
        });
    }
  }
};
</script>
