<template>
  <div class="home is-flex is-justify-content-center"
>
    <div
      v-for="campus in campuses"
      :key="campus.campusID"
      class="mx-5"
    >
      <router-link v-bind:to="{ name: 'campus', params: { campus: campus.abbreviation.trim() } }"
        ><img :src="campus.images.icon" style="width: 128px"
      /><h4>{{campus.name}}</h4></router-link>
    </div>
  </div>
</template>

<script>
import authService from "@/services/AuthService.js";

export default {
  name: "home",
  data() {
    return {
      campuses: [],
    };
  },
  created() {
    authService
      .getCampuses()
      .then((response) => {
        this.campuses = response.data;
      })
      .catch((error) => {
        error;
      });
  },
};
</script>
