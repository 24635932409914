<template>
  <div class="box">
      <div class="title is-2 has-text-centered">We're in the final stretch. Will your class take home the House of Metal Cup?</div>
      <figure class="image is-square">
      <img src="https://tehousecup.blob.core.windows.net/images/Hopeful.png" />
      </figure>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>