<template>
  <points-for-student />
</template>

<script>
import PointsForStudent from "@/components/PointsForStudent.vue";
export default {
  components: {
    PointsForStudent,
  },
};
</script>

<style>
</style>