import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

// Change this to true for production
Vue.config.productionTip = true
Vue.prototype.$hostname = (Vue.config.productionTip) ? 'https://api.house-cup.techelevator.com/api' : 'https://localhost:44315/api'
Vue.prototype.$version = (Vue.config.productionTip) ? "1.16.0913" : "1.0.9.0914"

axios.defaults.baseURL = Vue.prototype.$hostname;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

