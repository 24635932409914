<template>
  <div>
    <div
      class="
        is-flex is-justify-content-space-between is-flex-wrap-wrap
        box
        column
      "
    >
      <div
        class="is-flex is-justify-content-space-between block"
        style="flex-basis: 100%"
      >
        <p class="title is-5">Select a Class</p>
      </div>

      <div
        v-for="(classData, index) in $store.getters.classData"
        :key="index"
        class="box"
        :class="{ boxSelected: classData.classId === classId }"
      >
        <img
          :src="classData.raceImage"
          style="width: 127px"
          @click="classId = classData.classId"
        /><br />({{ classData.className }})
      </div>
    </div>
    <div class="columns">
      <div
        class="
          file
          is-info
          has-name
          is-flex is-justify-content-space-between is-align-content-flex-start
          box
          is-flex-wrap-wrap
          column
        "
      >
        <div
          class="
            is-flex is-justify-content-space-between
            block
            is-align-content-baseline
          "
          style="flex-basis: 100%"
        >
          <p class="title is-5">Upload a new file</p>
          <button
            class="button is-success"
            :disabled="!selectedFiles || classId == 0"
            @click="upload"
          >
            Upload
          </button>
        </div>

        <label class="file-label">
          <input
            class="file-input"
            ref="file"
            type="file"
            name="resume"
            @change="selectFile"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">Race Image </span>
          </span>
          <span class="file-name">{{ fileName || "" }} </span>
        </label>
        <img v-if="url" :src="url" />
      </div>
      <div
        class="
          is-flex is-justify-content-space-between is-flex-wrap-wrap
          box
          column
        "
      >
        <div
          class="
            is-flex is-justify-content-space-between
            block
            is-align-content-baseline
          "
          style="flex-basis: 100%"
        >
          <p class="title is-5">Select an existing file</p>
          <button
            class="button is-success"
            style="margin-top: auto"
            :disabled="!selectedImage || classId == 0"
            @click="selectImage"
          >
            Select
          </button>
        </div>
        <div
          v-for="blob in imageData.blobs"
          :key="blob.name"
          class="box"
          :class="{ boxSelected: blob.url === selectedImage }"
        >
          <img
            :src="blob.url"
            style="width: 127px"
            @click="selectedImage = blob.url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/APIService.js";

export default {
  name: "class_images",
  data() {
    return {
      selectedFiles: undefined,
      currentFile: undefined,
      classId: 0,
      fileName: "",
      imageData: {},
      selectedImage: "",
    };
  },
  methods: {
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
      this.fileName = this.selectedFiles[0].name;
      this.url = URL.createObjectURL(this.selectedFiles[0]);
    },
    selectImage() {
      APIService.uploadFile(
        undefined,
        this.$store.state.campus.campusID,
        this.classId,
        this.selectedImage
      )
        .then((response) => {
          this.selectedImage = response.data.imageURL;
          this.setRaceImage();
          this.$router.push("/admin");
        })
        .catch();
    },
    setRaceImage() {
      this.$store.commit("SET_CLASS_RACE_IMAGE", {
        classID: this.classId,
        url: this.selectedImage,
      });
    },
    upload() {
      this.currentFile = this.selectedFiles.item(0);
      APIService.uploadFile(
        this.currentFile,
        this.$store.state.campus.campusID,
        this.classId
      )
        .then((response) => {
          this.selectedImage = response.data.imageURL;
          this.setRaceImage();
          this.$router.push("/admin");
        })
        .catch();
      this.selectedFiles = undefined;
    },
  },
  created() {
    APIService.getAllImages(this.$store.state.campus.campusID).then(
      (response) => {
        this.imageData = response.data;
      }
    );
  },
};
</script>

<style>
.boxSelected {
  background-color: lightseagreen;
}
</style>