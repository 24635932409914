<template>
  <div>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>
    <div class="is-flex is-horizontal-center">
      <img src="@/assets/orcaloop3c.gif" v-if="!gotData" />
    </div>
    <table class="table is-fullwidth" v-if="gotData">
      <thead class="title is-5">
        <tr>
          <td>Reason</td>
          <td>Value</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr class="has-background-success-light">
          <td>
            <input
              type="input"
              class="input"
              v-model="newCategory.description"
            />
          </td>
          <td>
            <input
              type="number"
              class="input"
              v-model.number="newCategory.pointValue"
            />
          </td>
          <td>
            <i
              class="fas fa-plus-circle is-pulled-right fa-2x"
              style="color: #8cc63f"
              @click="addCategory"
            ></i>
          </td>
        </tr>
        <tr v-for="category in pointCategories" :key="category.categoryID">
          <td>{{ category.description }}</td>
          <td>{{ category.pointValue }}</td>
          <td>
            <section class="is-flex is-justify-content-space-around">
              <i class="far fa-edit"></i>&nbsp;<i
                class="
                  fas
                  fa-dumpster-fire
                  has-text-danger-dark
                  is-pulled-right
                "
                @click="deleteCategory(category.categoryID)"
              ></i>
            </section>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiService from "@/services/APIService.js";
export default {
  data() {
    return {
      pointCategories: {},
      errorMessage: "",
      gotData: true,
      newCategory: {},
    };
  },
  methods: {
    deleteCategory(categoryID) {
      categoryID;
    },
    addCategory() {
      this.gotData = false;

      apiService
        .postAddPointsCategory(
          this.$store.state.campus.campusID,
          this.newCategory
        )
        .then((response) => {
          this.pointCategories.push(response.data);
          this.gotData = true;
          this.newCategory = {};
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }

          this.errorMessage = response.data.message;
          this.gotData = true;
        });
    },
  },
  created() {
    apiService
      .getPointCategories(this.$store.state.campus.campusID)
      .then((response) => {
        this.pointCategories = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
      });
  },
};
</script>

<style>
</style>