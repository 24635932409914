<template>
  <students :modalIn='false' />
</template>

<script>
import Students from "@/components/Students.vue";

export default {
  components: {
    Students,
  },
  data() {
    return {
      errorMessage: "",
    };
  },
};
</script>

<style>
</style>