<template>
  <div>
    <p v-if="errorMessage" class="has-background-danger-dark has-text-white">
      {{ errorMessage }}
    </p>
    <div
      class="box is-flex is-justify-content-space-between"
      v-if="pointCategories"
    >
      <select
        name="pointCategory"
        id="pointCategory"
        class="input is-rounded"
        v-model="addPoints.selectedCategory"
        style="width:50%"
      >
        <option value="" disabled>-- Points For --</option>
        <option
          v-for="item in pointCategories"
          :value="item.categoryID"
          :key="item.categoryID"
        >
          {{ item.description }} ({{ item.pointValue }})
        </option>
      </select>
      <!-- <date-picker class="field" v-model="addPoints.awardedDate"></date-picker> -->
      <input class="input is-rounded" v-model="addPoints.awardedDate" type="date" id="AwardDate" style="width:25%"/>
      <button
        class="button is-success is-pulled-right content"
        type="button"
        :class="{ 'is-loading': saving }"
        :disabled="formFilled"
        @click="savePoints()"
      >
        Save Points
      </button>
    </div>
    <section class="columns">
      <div class="column box" v-for="(item, title) in students" :key="title">
        <div
          class="
            title
            is-6  pl-2
            has-text-white
          "
          :style="{ backgroundColor: $store.getters.classColors[title] }"
        >
          {{ title }}
          <br/>
          <label class="checkbox" :key="title">
            <input
              type="checkbox"
              class="checkbox"
              :id="title"
              @click="setAll($event, title)"
            />
            Select All
          </label>
        </div>
        <section class="is-flex is-flex-direction-column">
          <label
            class="checkbox"
            v-for="student in item"
            :key="student.studentID"
          >
            <input
              type="checkbox"
              :value="student.studentID"
              v-model="addPoints.pointsFor"
              class="checkbox"
              :id="title"
            />
            {{ student.firstName }} {{ student.lastName }}</label
          >
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import apiService from "../services/APIService";
// import DatePicker from "vuejs-datepicker";

export default {
  components: {
    // DatePicker,
  },
  data() {
    return {
      students: {},
      pointCategories: {},
      errorMessage: "",
      addPoints: {
        pointsFor: [],
        selectedCategory: "",
        awardedDate: "",
      },
      saving: false,
    };
  },
  methods: {
    setAll($event, classTitle) {
      if (event.target.checked) {
        this.students[classTitle].forEach((student) =>
          this.addPoints.pointsFor.push(student.studentID)
        );
      } else {
        this.addPoints.pointsFor = [];
      }
    },
    savePoints() {
      apiService
        .postAddPoints(this.addPoints)
        .then((response) => {
          this.students = response.data;
          this.$router.push("/admin");
        })
        .catch((error) => {
          const response = error.response;
          if (response.status === 401) {
            this.$store.commit("LOGOUT");
            this.$router.push({ name: "home" });
          }
          this.errorMessage = response.data.message;
        });
    },
  },
  computed: {
    formFilled() {
      return !(
        this.addPoints.selectedCategory && this.addPoints.pointsFor.length
      );
    },
  },
  created() {
    apiService
      .getStudents(
        this.$store.state.campus.campusID,
        this.$store.getters.currentCohort
      )
      .then((response) => {
        this.students = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
        this.errorMessage = response.data.message;
      });
    apiService
      .getPointCategories(this.$store.state.campus.campusID)
      .then((response) => {
        this.pointCategories = response.data;
      })
      .catch((error) => {
        const response = error.response;
        if (response.status === 401) {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "home" });
        }
        this.errorMessage = response.data.message;
      });
    this.addPoints.awardedDate = new Date().toISOString().substring(0, 10);
  },
};
</script>

<style>
</style>