<template>
  <div id="register" class="container">
    <form class="box" @submit.prevent="register">
      <h1 class="h3 mb-3 font-weight-normal">Create Account</h1>
      <div class="alert alert-danger" role="alert" v-if="registrationErrors">
        {{ registrationErrorMsg }}
      </div>
      <label for="fName" class="label">First Name</label>
      <input
        type="text"
        id="fName"
        class="input"
        placeholder="First Name"
        v-model="user.firstName"
        required
        autofocus
      />
      <label for="lName" class="label">Last Name</label>
      <input
        type="text"
        id="lName"
        class="input"
        placeholder="Last Name"
        v-model="user.lastName"
        required
      />
      <label for="email" class="label">Email</label>
      <input
        type="text"
        id="email"
        class="input"
        placeholder="Email"
        v-model="user.username"
        required
      />
      <label for="password" class="label">Password</label>
      <input
        type="password"
        id="password"
        class="input"
        placeholder="Password"
        v-model="user.password"
        required
      />
      <input
        type="password"
        id="confirmPassword"
        class="input"
        placeholder="Confirm Password"
        v-model="user.confirmPassword"
        required
      />
      <section class="box is-flex is-justify-content-space-between">
        <label class="checkbox" v-for="campus in campuses" :key="campus.campusID">
          <input
            type="radio"
            :value="campus.campusID"
            v-model="user.campus"
            class="radio"
            :id="campus.name"
          />
          {{ campus.name }}</label
        >
      </section>

      <router-link :to="{ name: 'login' }">Have an account?</router-link>
      <button
        class="button is-success is-pulled-right content"
        type="submit"
        :class="{ 'is-loading': registering }"
        :disabled="formFilled"
      >
        Create Account
      </button>
    </form>
  </div>
</template>

<script>
import authService from "../services/AuthService";

export default {
  name: "register",
  data() {
    return {
      user: {
        username: "",
        password: "",
        confirmPassword: "",
        role: "staff",
        campuses: [],
        campus: '',
      },
      campuses: {},
      registrationErrors: false,
      registrationErrorMsg: "There were problems registering this user.",
      registering: false,
    };
  },
  computed: {
    formFilled() {
      return !(this.user.campus && this.user.username && this.user.firstName && this.user.lastName);
    },
  },
  methods: {
    register() {
      if (this.user.password != this.user.confirmPassword) {
        this.registrationErrors = true;
        this.registrationErrorMsg = "Password & Confirm Password do not match.";
        this.registering = false;
      } else {
        this.registering = true;
        authService
          .register(this.user)
          .then((response) => {
            if (response.status == 201) {
              this.$router.push({
                path: "/login",
                query: { registration: "success" },
              });
            }
          })
          .catch((error) => {
            const response = error.response;
            this.registrationErrors = true;
            this.registering = false;
            
              this.registrationErrorMsg = response.data.message;

          });
      }
    },
    clearErrors() {
      this.registrationErrors = false;
      this.registrationErrorMsg = "There were problems registering this user.";
      this.registering = false;
    },
  },
  created() {
    authService.getCampuses().then((response) => {
      this.campuses = response.data;
    });
  },
};
</script>

<style></style>
