import axios from 'axios';

export default {

  getCampus(abbreviation) {
    return axios.get(`/campus/${abbreviation}`);
  },
  refreshData() {
    return axios.get(`/user`);
  },
  getClasses(campusid, cohortnumber) {
    return axios.get(`/campus/${campusid}/cohort/${cohortnumber}/classes`)
  },
  getStudents(campusid, cohortnumber) {
    return axios.get(`/campus/${campusid}/cohort/${cohortnumber}/students`)
  },
  getPointCategories(campusid) {
    return axios.get(`/points/campus/${campusid}/categories`)
  },
  postAddPointsCategory(campusid, categoryToAdd) {
    return axios.post(`/points/campus/${campusid}/categories`, categoryToAdd);
  },
  postAddPoints(addPoints) {
    return axios.post(`points`, addPoints)
  },
  getPointsByCampus(campusid, cohort) {
    return axios.get(`/points/campus/${campusid}/cohort/${cohort}`)
  },
  postAddStudents(students) {
    return axios.post(`/student`, students);

  },
  getPointsRace(campusid, cohort) {
    return axios.get(`/points/campus/${campusid}/cohort/${cohort}/pointsrace`);

  },
  getPointsByWeekByStudent(campusid, cohort) {
    return axios.get(`/points/campus/${campusid}/cohort/${cohort}/weekly`);

  },
  getPointsByStudent(campusid, cohort) {
    return axios.get(`/points/campus/${campusid}/cohort/${cohort}/students`);

  },
  getPointsForStudent(campusid, cohort, studentid) {
    return axios.get(`/points/campus/${campusid}/cohort/${cohort}/students/${studentid}`);

  },
  deleteStudent(studentid) {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return axios.delete(`/student/${studentid}`);

  },
  deleteHousePoints(housePointID) {
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return axios.delete(`/points/${housePointID}`);

  },
  uploadFile(file, campusID, classID, url) {
    let formData = new FormData();
    formData.append("fileIn", file);
    formData.append("url", url ?? "");
    return axios.post(`/campus/${campusID}/class/${classID}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  getAllImages(campusID) {
    return axios.get(`/campus/${campusID}/images`)
  }


}

