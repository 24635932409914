<template>
  <div id="modal_main" class="modal" :class="{ 'is-active': showEE }">
    <div class="modal-background"></div>
    <div class="modal-card" style="width: 60%">
      <header class="modal-card-head">
        <p class="modal-card-title">House of Metal!</p>
        <button class="delete" aria-label="close" @click="hideMe"></button>
      </header>
      <section class="modal-card-body">
        <section class="title is-flex is-align-content-center">
          Just another awesome tool brought to you by the Pittsburgh Instructor
          Crew!
        </section>
        <div class="columns">
          <div class="column">
            <img src="https://usefulimages.blob.core.windows.net/easteregg/MedvitzEgg.png" />
          </div>
          <div class="column">
            <img src="https://usefulimages.blob.core.windows.net/easteregg/Walt.png" />
          </div>
          <div class="column">
            <img src="https://usefulimages.blob.core.windows.net/easteregg/Beth.png" />
          </div>
          <div class="column">
            <img src="https://usefulimages.blob.core.windows.net/easteregg/Groovy.png" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="hideMe">Close</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showMe"],
  data() {
    return {
      showEE: this.showMe,
    };
  },
  methods: {
    hideMe() {
      this.showEE = false;
      this.$emit("shhh", false);
    },
  },
  watch: {
    showMe() {
      this.showEE = this.showMe;
    },
  },
};
</script>

<style>
.eeWidth {
  width: 50px;
}
</style>